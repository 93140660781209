/* eslint-disable @next/next/no-img-element */
import { BannerPromotionHome, MovieSection, NavigateBooking } from '@/components/screen';
import TabSelectMovieMobile from '@/components/tabMovieMobile';
import useContentGlobal from '@/hooks/useContentGlobal';
import { TabsProps } from 'antd';
import dynamic from 'next/dynamic';
import { useState } from 'react';
const MemberHome = dynamic(() => import('@/components/screen').then((mod) => mod.MemberHome), { ssr: false })
const ServiceHome = dynamic(() => import('@/components/screen').then((mod) => mod.ServiceHome))
const ContactHome = dynamic(() => import('@/components/screen').then((mod) => mod.ContactHome), { ssr: false })
const PromotionHome = dynamic(() => import('@/components/screen').then((mod) => mod.PromotionHome))
type TProps = {
  res: {
    res: {
      listMovie: TMovie[]
      listComingMovie: TMovie[]
      listCinemas: TCinima[]
    }
    ortherService: {
      ortherService_en: string,
      ortherService_vn: string
    }
    memberShip: {
      memberShip_en: string,
      memberShip_vn: string
    }
    listPromotion: string
    footerContactCms: {
      left: string
      top: string
    }
  }
}

const MAX_SLIDES = 12

export const HomePage: React.FC<TProps> = ({ res }) => {
  const { getValueByKey } = useContentGlobal();
  const [movies] = useState<TMovie[]>(res?.res?.listMovie)

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: <div className="tabs-heading">
        <h1>{getValueByKey("Now_Showing") ?? 'Phim đang chiếu'}</h1>
      </div>,
      children: <MovieSection mobileItem maxItems={MAX_SLIDES} noTitle movies={movies} isShowMore isShowMoreUrl={"/movie/showing"} title={getValueByKey("Now_Showing") ?? 'Phim đang chiếu'} />,
    },
    {
      key: '2',
      label: <div className="tabs-heading">
        <h1>{getValueByKey("Coming_Soon") ?? 'Phim sắp chiếu'}</h1>
      </div>,
      children: <MovieSection mobileItem maxItems={MAX_SLIDES} isLazy isComingSoon noTitle movies={res?.res?.listComingMovie} isShowMore isShowMoreUrl={"/movie/upcoming"} title={getValueByKey("Coming_Soon") ?? 'Phim sắp chiếu'} />,
    },
  ];

  return (
    <>
      <BannerPromotionHome listPromotion={res?.listPromotion} />
      <NavigateBooking listCinemas={res?.res?.listCinemas} listBaseMovies={movies}/>
      <MovieSection maxItems={MAX_SLIDES} classNames='desktop' movies={movies} isShowMore isShowMoreUrl={"/movie/showing"} title={getValueByKey("Now_Showing") ?? 'Phim đang chiếu'} />
      <MovieSection maxItems={MAX_SLIDES} classNames='desktop' isComingSoon movies={res?.res?.listComingMovie} isShowMore isShowMoreUrl={"/movie/upcoming"} title={getValueByKey("Coming_Soon") ?? 'Phim sắp chiếu'} />
      <TabSelectMovieMobile className='display-mobile' tabContent={items} />
      <PromotionHome listPromotion={res?.listPromotion} />
      <MemberHome memberShip={res?.memberShip} />
      <ServiceHome ortherService={res?.ortherService} />
      <ContactHome footerContactCms={res?.footerContactCms} />
    </>
  )
}
